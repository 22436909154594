.about-simulation {
  background-image: url('../../../assets/background-about-dark-blue.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 45vw;
  height: 100%;

  &__layout {
    max-width: calc(@max-width-screen);
    padding: 7vw @padding-layout-x;
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @phone-sm {
      padding: 7vw @padding-layout-x-mobile;
    }
  }

  &__title {
    font: normal 1.5vw/1.7vw Archivo;    
    color: @white;
    text-align: center;
    max-width: 14vw;
    margin-top: 5vw;

    @media @tab-md {
      font: normal 2vw/2.2vw Archivo;    
    }

    @media @phone-sm {
      font: normal 2.7vw/3vw Archivo;    
    }
  }
  
  &__title-claimfy {
    font: normal 1.5vw/1.7vw Archivo;    
    color: @white;
    text-align: center;
    max-width: 14vw;
    margin-top: 3vw;
    margin-left: auto;
    margin-right: auto;

    @media @tab-md {
      max-width: 22vw;
      font: normal 2vw/2.2vw Archivo;    
    }

    @media @phone-sm {
      font: normal 2.7vw/3vw Archivo;    
    }
  } 

  &__jive {
    height: 8vw;

    @media @tab-md {
      height: 10vw;    
    }
  }

  &__claimfy {
    margin-top: 2.9vw;
    height: 7vw;

    @media @tab-md {
      margin-top: 3.5vw;
      height: 9vw;    
    }
  }

  &__border {
    border: 1px dashed @secondary-color;
    height: 100%;
    margin: 0 6vw;
  }

  &__text {
    font: normal bold 1.5vw/1.7vw Archivo;    
    color: @secondary-color;
    margin-top: 7vw;

    @media @tab-md {
      font: normal bold 2vw/2.2vw Archivo;    
    }
    
    @media @phone-sm {
      font: normal bold 2.7vw/3vw Archivo;    
    }
  }

  &__button {
    margin-top: 1vw;
    height: 3.5vw;
    width: 15vw;
    font: normal bold 1.6vw/1.8vw Archivo;    

    @media @tab-md {
      margin-top: 2vw;
      width: 18vw;
      font: normal bold 2vw/1.3vw Archivo;    
    }

    @media @phone-sm {
      width: 40vw;
      height: 7vw;
      font: normal bold 3vw/2vw Archivo;    
    }
  }
}