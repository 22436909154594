.error-login {
  &__body {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;

    @media @tab-lg {
      height: 100%;
    }

    @media @tab-lg{
      max-width: 100%;
    }
  }

  &__input-title {
    color: @primary-text-color;
    font: normal bold 3vw/3vw Lato;

    @media @tab-lg {
      font: normal bold 6.5vw/7vw Lato;
    }

    @media @phone-sm {
      font: normal bold 9.5vw/10vw Lato;
    }
  }

  &__text {
    margin-top: 3rem;
    color: @primary-text-color;
    font: normal 1.5vw/1.7vw Lato;

    @media @tab-lg {
      margin-top: 2rem;
      font: normal 3.5vw/4vw Lato;
      max-width: calc(100% - 10%);
    }
    
    @media @phone-sm {
      max-width: 100%;
      margin-top: 1.5rem;
      font: normal 6vw/6.5vw Lato;
    }
  }

  &__sub-text {
    max-width: calc(100% - 10%);
    margin-top: 2rem;
    color: @primary-text-color;
    font: normal 1.5vw/1.7vw Lato;

    @media @tab-lg {
      margin-top: 1.5rem;
      font: normal 3.5vw/4vw Lato;
    }


    @media @phone-sm {
      margin-top: 1rem;
      font: normal 6vw/6.5vw Lato;
    }
  }

  &__next-button {
    height: 3vw;
    color: @white;
    width: 22vw;
    font: normal bold 1.3vw/1.5vw Archivo;

    @media @tab-lg {
      height: 5vw;
      width: 35vw;
      font-size: 2vw;
    }

    @media @phone-sm {
      height: 7vw;
      width: 50vw;
      font-size: 2.9vw;
    }
  }

  &__footer {
    position: absolute;
    bottom: 2vw;
    width: 90%;

    @media @phone-sm {
      padding-right: 2vw;
      width: 85vw;
      bottom: 4vw;
    }
  }
}