.confirm-email {
  &__name {
    color: @primary-text-color;
    font: normal bold 3vw/3.2vw Lato;
    text-align: center;

    @media @tab-xl {
      margin-top: -1.5vw;
    }

    @media @tab-md {
      margin-top: -2.5vw;
    }

    @media @phone-sm {
      font: normal bold 7vw/7.2vw Lato;
    }
  }

  &__body {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media @tab-lg {
      height: 100%;
    }
  }

  &__input-error {
    border: 1px solid #ff4d4f;
  }

  &__span-error {
    display: block !important;
    text-align: center;
    color: #ff4d4f;
    font-size: 0.9vw !important;
    min-height: 0 !important;

    @media @tab-md {
      font-size: 2vw !important;
      min-height: fit-content;
    }
  
    @media @phone-xs {
      font-size: 2.5vw !important;
      min-height: fit-content;
    }
  }

  &__bottom {
    margin-bottom: 2vw;
  }

  &__sub-title {
    margin-top: 3.5vw;
    margin-bottom: 1vw;
    color: @primary-text-color;
    font: normal 1.5vw/2vw Lato;
    text-align: center;

    @media @tab-lg {
      margin-top: 2.5vw ;
      font: normal 2.5vw/3vw Lato;
      margin-bottom: 0;
    }

    @media @phone-sm {
      margin-bottom: 1vw;
      font: normal 4vw/4.5vw Lato;
    }
  }

  &__input-title {
    color: @primary-text-color;
    text-transform: uppercase;
    font: normal bold 1.2vw/1.5vw Lato;

    @media @tab-lg {
      font: normal bold 2.5vw/3vw Lato;
    }

    @media @phone-sm {
      margin-bottom: 2vw;
      font: normal bold 3.5vw/4vw Lato;
    }
  }

  &__edit {
    transform: translateX(30px);
    max-width: 40%;
    margin-top: 5px;
    font-size: 1.1rem;

    @media @tab-xl {
      transform: translateX(25px);
    }

    @media @tab-lg {
      max-width: 70%;
    }

    @media @tab-md {
      transform: translateX(25px);
      height: 4vw;
      margin-left: 0;
    }

    @media @phone-sm {
      height: 8vw;
      margin-left: 0;
      max-width: 50%;
    }

    @media @phone-sm {
      max-width: 65%;
    }
  }

  &__cellphone-field {
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
    display: block;

    @media @tab-md {
      height: 4vw;
    }

    @media @tab-lg {
      max-width: 70%;
    }

    @media @phone-sm {
      height: 8vw;
    }
  }

  &__SMS-text {
    text-align: center;
  }

  &__email-subtitle {
    margin-left: auto;
    margin-right: auto;
    font-size: 1vw;

    @media @tab-lg {
      font-size: 1.5vw;
    }

    @media @phone-sm {
      margin-top: 1vw;
      margin-bottom: 5vw;
      font-size: 2.5vw;
    }
  }

  &__pencil-edit {
    transform: translateX(35%);
    font-size: 2vw;
    margin-top: -0.2vw;

    &:hover {
      filter: brightness(1.2);
    }

    @media @tab-md {
      font-size: 2.5vw;
    }

    @media @phone-sm {
      margin-top: -0.4vw;
      font-size: 5vw;
    }
  }

  &__send-code {
    margin-top: 2vw;
    color: @primary-text-color;
    font: normal 1vw/1.2vw Lato;

    @media @tab-lg {
      margin-top: 0;
      font: normal 1.6vw/2vw Lato;
    }

    @media @phone-sm {
      margin-top: 1.5vw;
      font: normal 4vw/4.2vw Lato;
    }
  }

  &__send-code-link {
    text-decoration: underline;
    font: normal 1vw/1.2vw Lato;
    color: @primary-color;
    margin-top: -0.5rem;

    & span {
      text-decoration: underline;
    }

    &:hover {
      color: lighten(@primary-color, 5%);
      text-decoration: underline;
    }

    @media @tab-lg {
      margin-top: -0.7rem;
      font: normal 1.6vw/2vw Lato;
    }

    @media @phone-sm {
      margin-top: -0.5rem;
      font: normal 4vw/4.2vw Lato;
    }
  }
  &__footer {
    position: absolute;
    bottom: 2vw;
    width: 90%;

    @media @phone-sm {
      padding-right: 2vw;
      width: 85vw;
      bottom: 4vw;
    }
  }
}