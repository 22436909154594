.navbar {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 102px;
  margin: auto;
  background-color: @white;
  padding: 0;
  
  @media @desktop-xxl {
    height: 5vw;
  }

  @media @tab-md {
    height: 70px;
    position: absolute;
  }

  &__simule-button {
    position: absolute;
    background-color: darken(@secondary-color, 10%);
    font: normal bold 3.5vw/3.5vw Lato;
    padding: 0 8vw;
    color: #003564;
    opacity: 1;
    text-transform: uppercase;
    border-color: @secondary-color;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.65);

    &:hover, &:focus {
      color: darken(#003564, 10%);
      border-color: darken(@secondary-color, 20%);
      background-color: darken(@secondary-color, 20%);
    }

    @media @tab-md {
      max-width: 50vw;
      font: normal bold 2vw/2vw Lato;
    }

    @media @phone-sm {
      font: normal bold 3.5vw/3.5vw Lato;
    }
  }

  &__mobile {
    @media @tab-md {
      z-index: 15;
      position: fixed;
      background-color: #003564;
    }
  }

  &__button-about {
    text-transform: uppercase;
    border: 1px solid @primary-text-color;
    color: @primary-text-color;
    width: 20rem;
    height: 3rem;
    font: normal bold 0.9rem/0.9rem Lato;

    @media @tab-lg {
      width: 27vw;
      height: 3.5vw;  
      font-size: 1.2vw;
    }
  }

  &__selected-item {
    max-width: 80%;
    margin: auto;
    margin-bottom: 0.7rem;
    margin-top: -0.7rem;
    text-align: center;
    border: 2px solid @secondary-color ;
    margin-right: 2vw;

    @media @tab-lg {
      margin-bottom: 2.5vw;
      margin-top: -2.5vw;  
    }
  }

  &__items {
    margin: auto;
    padding: 0 @padding-layout-x;
    max-width: @max-width-screen;
    height: 100%;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @tab-md {
      justify-content: flex-end;
    }

    @media @tab-md {
      padding: 0 @padding-layout-x-mobile;
    }
  }

  &__image {
    height: 3vw;

    @media @desktop-xxl {
      height: 2vw;
    }

    @media @tab-md {
      height: 6vw;
    }

    @media @phone-sm {
      height: 8vw;
    }
  }

  &__toggle-menu {
    display: none;
    left: 1.5rem;
    color: @primary-text-color;
    font-size: 22px;
    transition: opacity .25s;
    position: absolute;

    @media @tab-md {
      display: initial;
    }

    &--off {
      opacity: 1;
    }
  }

  &__image-sindifisco {
    margin-left: 1rem;
    height: 3vw;

    @media @desktop-xxl {
      height: 2vw;
      margin-left: 5rem;
    }

    @media @tab-md {
      height: 6vw;
      margin-left: 10px;
    }

    @media @phone-sm {
      height: 8vw;
    }
  }

  &__menu {
    @media @desktop-xxl {
      flex: 0 0 700px;
    }
    @media @tab-lg {
      flex: 0 0 370px !important;
    }
    @media @tab-md {
      display: none;
    }
  }

  &__menu-item {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    color: @primary-text-color;

    @media @tab-lg {
      font-size: 1.2vw;
    }
  }

  &__menu-item-about {
    margin-right: 2vw;
  }
}