.progress-steps {
  background-color: @background-steps;
  
  &__layout {
    max-width: calc(@max-width-screen);
    padding: 7vw @padding-layout-x;
    width: 100%;
    height: 100%;
    margin: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @tab-xl {
      padding: 5rem @padding-layout-x;
    }

    @media @tab-md {
      padding: 7vw 1.7rem;
    }

    @media @phone-sm {
      padding: 10vw 2.5rem 15vw;
    }
  }

  &__title {
    text-align: left;
    max-width: 50vw;
    font: normal 600 3vw/3.5vw Archivo;
    letter-spacing: 0px;
    color: @primary-text-color;
    text-transform: uppercase;
    margin-bottom: 0.3vw;

    @media @tab-md {
      font: normal 600 5.5vw/6vw Archivo;
      max-width: 100%;
    }
    
    @media @phone-sm {
      max-width: 50vw;
      font: normal 600 8vw/8.5vw Archivo;
      max-width: 80vw;
    }
  }

  &__my-step {
    max-width: 16vw;

    @media @tab-md {
      max-width: 20vw;
    }

    @media @phone-sm {
      display: none;
    }
  }

  &__mobile-version {
    @media @phone-sm {
      flex-direction: column;
      align-content: center;
      margin-left: 10vw;
      margin-top: 5vw;
    }
  }

  &__trail-vertical {
    @media @phone-sm {
      flex-direction: column;
      position: absolute;
      width: 1px;
      height: 100%;
      border-left: 2px dashed #797979;
    }
  }

  &__my-step-pin {
    display: block;
    width: 3.5vw;
    margin: auto;
    margin-bottom: 0.7vw;

    @media @tab-md {
      width: 5vw;
    }

    &--mobile {
      display: none;
      @media @phone-sm {
        display: initial;
        width: 10vw;
        margin: 0;
        margin-left: -12vw;
        transform: translateX(-47%) !important;
      }
    }
  } 

  &__my-step-text {
    color: @primary-text-color;
    text-align: center;
    font-size: 1.2vw;
    font-weight: bold;
    text-transform: uppercase;

    @media @tab-md {
      font-size: 1.7vw;
    }
  }

  &__sub-title {
    font: normal 1.5vw/1.7vw Lato;
    letter-spacing: 0px;
    color: @primary-text-color;
    margin-bottom: 4rem;

    @media @tab-xl {
      margin-bottom: 2rem;
    }

    @media @tab-md {
      font: normal 2.5vw/2.7vw Archivo;
    }

    @media @phone-sm {
      max-width: 50vw;
      font: normal 400 4vw/4.5vw Archivo;
      margin-bottom: 2vw;
    }
  }
  
  &__card {
    max-width: 16vw;

    @media @tab-md {
      max-width: 20vw;
    }

    @media @phone-sm {
      max-width: 57vw;
      position: relative;
    }
  }

  &__step-card {
    position: relative;
    background-color: @primary-color;
    border-radius: 0 1.3vw 1.3vw 1.3vw;
    height: 8vw;
    width: 16vw;
    margin: 1.8vw 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: @white;
    font: normal bold 1.4vw/2.3vw Archivo;
    text-transform: uppercase;

    &--disabled {
      background-color: #DADADA;
      color: #797979 !important;
    }

    @media @tab-md {
      width: 20vw;
      height: 10vw;
      font: normal bold 1.8vw/2.7vw Archivo;
    }

    @media @phone-sm {
      font: normal bold 5.2vw/5.3vw Archivo;
      height: 28vw;
      margin-top: 1vw;
      margin-bottom: 3vw;
      width: 57vw;
      border-radius: 0 7vw 7vw 7vw;
    }
  }

  &__step-card-position {
    position: absolute;
    background-color: @secondary-color;
    border-radius: 100px;
    top: 0;
    left: 0;
    transform: translate(-50%, 45%);
    height: 4vw;
    width: 4vw;
    font: normal 900 2.2vw/4vw Lato;
    color: @primary-text-color;

    &--disabled {
      background-color: #CBCBCB;
      color: @white;
    }

    @media @tab-md {
      transform: translate(-50%, 70%);
    }

    @media @phone-sm {
      height: 14vw;
      width: 14vw;
      font: normal 900 7.6vw/14vw Lato;
      transform: translate(-50%, 45%);
    }
  }

  &__color-text {
    color: @primary-color;
    font-weight: bold;
  }

  &__vertical-progress {
    @media @phone-sm {
      margin-left: -12vw;
    }
  }

  &__legend-step {
    display: none;
    @media @phone-sm {
      display: initial;
      position: absolute;
      left: 0;
      text-transform: uppercase;
      color: @primary-text-color;
      font: normal bold 4.5vw/4.5vw Lato;
    }

    &--disabled {
      @media @phone-sm {
        color: #797979;
      }
    }
  }

  &__dot {
    background-color: @primary-text-color;
    height: 1.1rem;
    width: 1.1rem;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50px;

    &--disabled {
      background-color: @white;
      border: 2px solid #a1a1a1;
    }

    @media @phone-sm {
      display: none;
    }
  }

  &__dot-mobile {
    @media @phone-sm {
      background-color: @primary-text-color;
      height: 5vw;
      width: 5vw;
      position: relative;
      border-radius: 50px;
      transform: translate(-47%, 0);
  
      &--disabled {
        background-color: @white;
        border: 2px solid #a1a1a1;
      }  
    }
  }

  &__tails {
    position: absolute;
    margin-top: 0.5rem;
    z-index: 0;
    width: calc(100% - 17vw);
    left: 8.5vw;
    border: 1px dashed #797979;

    @media @tab-md {
      left: 10vw;
      width: calc(100% - 20vw);
    }

    @media @phone-sm {
      display: none;
    }
  }

  &__step-card-subtitle {
    width: 100%;
    color: #797979 !important;
    text-align: center !important;
    font: normal 1.34vw/1.7vw Lato !important;

    &--disabled {
      color: #797979 !important;
    }

    @media @tab-md {
      font: normal 2.1vw/2.4vw Lato !important;
    }

    @media @phone-sm {
      width: 107%;
      margin-left: -7%;
      font: normal 4.5vw/5vw Lato !important;
      margin-bottom: 10vw;
    }
  }
}