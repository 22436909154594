.simulation {
  position: relative;
  background-image: url('../../../assets/background-simulation-new.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 48.3vw;
  height: 100%;
  z-index: 11;

  @media @desktop-xxl {
    margin-top: 1.5vw;
  }

  @media @tab-md {
    background: linear-gradient(white, #003564);
    background-image: url('../../../assets/background-simulation-new.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vw;
    margin-top: 0;
  }

  @media @phone-xs {
    height: 75vw;
  }

  &__layout {
    max-width: calc(@max-width-screen);
    padding: 0 @padding-layout-x;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @tab-md {
      display: flex;
      padding: 0 @padding-layout-x-mobile;
    }
  }

  &__background {
    position: relative;
    width: 100%;
  }

  &__form-mobile {
    display: initial;
    @media @tab-md {
      display: none;
    }
  }

  &__mobile-background {
    display: none;
    @media @tab-md {
      display: inherit;
      background: @primary-text-color;
      padding-bottom: 40vw;
      margin-top: -4vw;
    }

    @media @phone-xs {
      padding-bottom: 50vw;
    }
  }

  &__banner-background {
    position: absolute;
    height: 20vw;
    width: calc(100% + 11vw);
    left: -10vw;
    top: -2.5vw;
    border-top-right-radius: 10vw;
    opacity: 0.4;
    z-index: -1;
    background: @background-banner;
  }

  &__girl {
    z-index: 1;
    height: 103%;
    bottom: 0;
    transform: translate(85%);
    position: absolute;

    @media @tab-md {
      display: none;
    }
  }

  &__go-down-mobile {
    @media @tab-md {
      margin-left: auto;
      margin-right: auto;
      display: block;
      text-align: center;
      transform: translate(0, 35vw);
    }

    @media @phone-sm {
      transform: translate(0, 36vw);
    }

    @media @phone-xs {
      transform: translate(0, 45vw);
    }
  }

  &__logos {
    margin-top: 5vw;

    @media @tab-md {
      height: 15%;
    }
  }

  &__sindifisco-image {
    margin-left: 1rem;

    @media @tab-md {
      margin-left: auto;
      height: 15%;
    }
  }

  &__text-title {
    margin-top: 4vw;
    padding-right: 1.5vw;
    max-width: 43vw;
    height: 50px;
    text-align: left;
    font: normal 400 2.8vw/3.2vw Archivo;
    letter-spacing: -0.8px;
    text-transform: uppercase;
    z-index: 1;
    color: @secondary-color;
    & > div > span {
      font: normal bold 2.8vw/3.2vw Archivo;
      color: @white;
    }

    @media @tab-md {
      display: none;
    }
  }

  &__banner {
    display: none;
    @media @tab-md {
      display: initial;
      width: 90%;
      position: absolute;
      background: @background-banner 0% 0% no-repeat padding-box;
      opacity: 0.89;
      padding: 2rem;
      transform: translate(13%, 30vw);
    }
  }

  &__title {
    text-transform: uppercase;
    color: @secondary-color;
    font: normal 300 8vw/8vw Archivo;
    & > span {
      text-transform: uppercase;
      font: normal bold 8vw/8vw Archivo;
      color: @white;
    }
  }

  &__divider {
    margin: 2vw 0;
    width: calc(100% + 1vw);
    height: 0.25vw;
    background: transparent linear-gradient(90deg, #5CC0F6 80%, #9CF03200 100%) 0% 0% no-repeat padding-box;
    z-index: 1;

    @media @tab-md {
      width: 100%;
      height: 2px;
    }
  }

  &__sub-text {
    width: calc(100% - 2vw);
    color: @white;
    margin-top: 2vw;
    text-transform: initial;
    text-align: left;
    font: normal 400 1.5vw/1.7vw Archivo;
    letter-spacing: 0px;

    & > span {
      color: @secondary-color;
      font-weight: bold;
    }

    @media @tab-md {
      width: calc(100% - 5vw);
      font: normal 400 4.5vw/5vw Archivo;
    }
  }

  &__sub-title {
    width: calc(100% - 2vw);
    color: @white;
    text-transform: initial;
    text-align: left;
    font: normal 400 1.5vw/1.7vw Archivo;
    letter-spacing: 0px;

    @media @tab-md {
      width: calc(100% - 5vw);
      font: normal 400 4.5vw/5vw Archivo;
    }
  }

  &__button-more {
    margin: 2.7vw 0 3.5vw;
    height: 3.2vw;
    width: 12.5vw;
    font-size: 1.45vw
  }

  &__text-go-down {
    margin-bottom: 0.5vw;
    width: calc(100% - 20vw);
    text-align: left;
    font: normal normal 900 1.3vw/1.8vw Lato;
    letter-spacing: 0px;
    color: @white;
    text-transform: uppercase;
    opacity: 1;

    @media @tab-md {
      width: 100%;
      margin-bottom: 2.5vw;
      font: normal 700 5vw/5.5vw Lato;
      display: block;
      text-align: center;
    }

    @media @phone-xs {
      margin-bottom: 1vw;
      font: normal 700 4.5vw/5vw Lato;
    }
  }

  &__proposal {
    text-align: center;
    margin-left: auto;
    margin-top: 1rem;
    max-width: 30vw;
    min-width: 28rem;
    & > div {
      & > a {
        color: @white;
        text-decoration: underline !important;
        font-weight: 600;

        &:hover {
          color: @secondary-color
        }
      }
      font: normal 400 1.2vw/1.5vw Lato;
      color: @white
    }

    @media @tab-xl {
      margin-top: 0.5rem;
      min-width: 20rem;
      max-width: 37vw;
    }
  }
}

.home-simulation {
  position: fixed;
  top: 8rem;
  width: 12vw;
  right: -1.2rem;
  cursor: pointer;
  z-index: 11;

  @media @tab-lg {
    width: 15vw;
  }

  @media @tab-md {
    display: none;
  }
}

.home-chat {
  position: fixed;
  bottom: 4rem;
  width: 4.5vw;
  right: 4rem;
  cursor: pointer;
  z-index: 13;

  @media @tab-lg {
    width: 5.5vw;
    right: 2rem;
    bottom: 2rem;
  }

  @media @phone-sm {
    display: none;
  }
}

#chat-card:not(:focus) {
  animation: fadeInAnimation ease 300ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
