.banner {
  position: relative;
  background-image: url('../../../assets/banner-hd.png');
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 45vw;

  @media @phone-sm {
  background-image: url('../../../assets/banner-hd.png');
  background-size: cover;

    min-height: 100vw;
    margin-bottom: 20vw;
  }

  &__layout {
    max-width: calc(@max-width-screen);
    padding: 10rem @padding-layout-x;
    width: 100%;
    height: 100%;
    margin: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @phone-sm {
      display: none;
    }
  }

  &__title {
    margin-top: -3rem;
    height: 19vw;
    width: 50vw;
    margin-left: auto;
    font: normal 200 5.1vw/6vw Archivo;
    color: @secondary-color;
    text-transform: uppercase;
    font-weight: 400;
    text-align: right;

    &--white {
      color: @white !important;
      font-weight: bold;
    }

    @media @tab-xl {
      margin-top: -4rem;
    }

    @media @tab-lg {
      margin-top: -6rem;
    }

    @media @tab-md {
      margin-top: -8rem;
      width: 45vw;
    }
  }

  &__image {
    display: block;
    margin-left: auto;
    width: 30vw;

    @media @tab-md {
      width: 50vw;
      margin-top: 5vw;
    }
  }

  &__vertical-line {
    border-right: 0.2rem solid @white;

    @media @phone-sm {
      border-right: none;
      border-left: 0.15rem solid @white;
    }
  }

  &__text {
    width: 35vw;
    text-align: right;
    margin-right: 1.5rem;
    margin-left: auto;
    font: normal 300 2.3vw/2.9vw Archivo;
    letter-spacing: 0px;
    color: @secondary-color;
    text-transform: uppercase;

    @media @phone-sm {
      margin-top: 10vw;
      width: 50vw;
      text-align: left;
      margin-left: 0.4rem;
      margin-right: auto;
      font: normal 200 4.5vw/5vw Archivo;
    }
  }

  &__sub-text {
    width: 35vw;
    margin-right: 1.5rem;
    margin-left: auto;
    text-align: right;
    font: normal 300 2.3vw/2.9vw Archivo;
    letter-spacing: 0px;
    color: @white;
    text-transform: uppercase;

    @media @phone-sm {
      width: 50vw;
      text-align: left;
      margin-left: 0.4rem;
      margin-right: auto;
      font: normal 200 4.5vw/5vw Archivo;
    }
  }

  &__floating {
    display: none;
    @media @phone-sm {
      display: initial;
      width: 80%;
      position: absolute;
      background: @background-banner 0% 0% no-repeat padding-box;
      opacity: 0.89;
      padding: 6vw 7vw;
      transform: translate(25%, 65vw);
    }
  }

  &__floating-title {
    text-transform: uppercase;
    color: @secondary-color;
    text-transform: uppercase;
    & > div.center {
      width: fit-content;
      font: normal 6.5vw/10vw Archivo;
      color: @secondary-color;
      text-align: center;
    }
    & > div {
    font: normal 7vw/7vw Archivo;
    color: @white;
      width: 100%;
      text-align: end;
    }
  }
}