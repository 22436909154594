#root{
  .sidebar {
    height: fit-content;
    z-index: 15;
    display: none;

    @media @tab-md {
      display: initial;
      position: fixed;
      &.collapsed {
        width: 0px!important;
        min-width: 0px!important;
      }
    }

    &__closemenu {
      position: absolute;
      left: 31px;
      height: 22px;
      top: 50%;
      color: @white;
      transform: translateY(-50%);
      svg {
        font-size: 22px;
        cursor: pointer;
      }
      z-index: 16;
    }

    &__group-item {
      & > a, span {
        &:focus {
          color: @primary-text-color;
        }
      }
    }

    &__menu-item {
      text-transform: uppercase;
      font-size: 0.9em;
      font-weight: bold;
      margin-left: 15px;
      color: @white;
    }

    & > .pro-sidebar-inner {
      width: 100%;
      max-width: 240px;
      overflow-x: hidden;
      background: @primary-color !important;
      color: @text-color;
    }

    .pro-sidebar-header {
      min-height: 70px;
      position: relative;
      border: none;
    }

    &__item-overflow {
      overflow: initial;
    }

    &__header {
      background: @background-header !important;
      max-height: 70px;
      &--off {
        @media @tab-md {
          .sidebar__closemenu {
            display: none;
          }
        }
      }
    }

    .pro-sidebar-content {
      overflow-y: auto ;
    }

    .pro-menu-item.active, .pro-menu-item.active:hover, .pro-menu-item.active > .pro-inner-item:hover  {
      color: @primary-color !important;
    }
    .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
      display: inline-block !important;
    }

    .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
      content: none !important;
    }

    .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
      background-color: @white !important;
    }

    .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul{
      padding: 0;
    }

    .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item.popper-element {
      box-shadow: 3px 3px 3px black;
    }

    .popper-inner {
      background: @white !important;
    }

    .pro-icon-wrapper {
      background: transparent!important;
      font-size: 1.2em!important;
    }

    &:not(.collapsed) .popper-inner {
      display: none;
    }
  }
}
