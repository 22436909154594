@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,300&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// GENERAL
@import 'helpers/layout-helper.less';
@import 'abstracts/variables.less';
@import 'abstracts/base.less';

// COMPONENTS
@import '../components/IntroAbout/introAbout.less';
@import '../components/AboutSection/aboutSection.less';
@import '../components/AboutGoSimulation/aboutGoSimulation.less';
@import '../components/AboutCircles/aboutCircles.less';
@import '../components/Navbar/navbar.less';
@import '../components/Sidebar/sidebar.less';
@import '../components/Terms/modalTerms.less';
@import '../components/Simulation/simulation.less';
@import '../components/Banner/banner.less';
@import '../components/Chat/chat.less';
@import '../components/Faq/faq.less';
@import '../components/Footer/footer.less';
@import '../components/ProgressSteps/progressSteps.less';
@import '../components/Simulation/components/simulationForm.less';
@import '../components/Simulation/components/simulationCard.less';
@import '../components/Simulation/components/successConfirm.less';
@import '../components/Simulation/components/simulationError.less';
@import '../components/Simulation/components/simulationErrorLogin.less';
@import '../components/Simulation/components/simulationPrecatorio.less';
@import '../components/Simulation/components/simulationConfirmNumber.less';
@import '../components/Simulation/components/simulationConfirmEmail.less';
@import '../components/Simulation/components/simulationRedirect.less';


body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif !important;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
}