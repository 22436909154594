.about-circles {
  background-image: url('../../../assets/white-about-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 10vw;
  height: 100%;

  &__layout {
    max-width: calc(@max-width-screen);
    padding: 7vw @padding-layout-x;
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @phone-sm {
      padding: 7vw @padding-layout-x-mobile;
    }
  }

  &__circle {
    border-radius: 50%;
    background-color: #06B07DAA;
    height: 17vw;
    width: 17vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -1vw;

    &--light {
      background-color: #9CF032AA;
    }

    @media @tab-md {
      height: 20vw;
      width: 20vw;
    }

    @media @phone-sm {
      height: 23vw;
      width: 23vw;
    }

    @media @phone-xs {
      height: 22vw;
      width: 22vw;
    }
  }

  &__sub-title {
    color: @white;
    max-width: 9.5vw;
    text-align: center;
    margin-top: 1vw;
    font: normal 1.5vw/1.7vw Archivo;
    
    &--light {
      color: @primary-text-color;
    }

    @media @tab-md {
      max-width: 10vw;
      font: normal 2vw/2.3vw Archivo;
    }

    @media @phone-sm {
      max-width: 15vw;
      font: normal 2.3vw/2.5vw Archivo;
    }

    @media @phone-xs {
      max-width: 17vw;
      font: normal 2.6vw/2.9vw Archivo;
    }
  }

  &__title {
    color: @white;
    text-align: center;
    font: normal bold 2.5vw/2.7vw Archivo;    
    
    &--light {
      color: @primary-text-color;
    }

    @media @tab-md {
      font: normal bold 2.7vw/2.9vw Archivo;
    }

    @media @phone-sm {
      font: normal bold 3vw/3.2vw Archivo;
    }

    @media @phone-xs {
      font: normal bold 3.2vw/3.5vw Archivo;
    }
  }
}