.footer {
  &__layout {
    max-width: calc(@max-width-screen);
    padding: 0 @padding-layout-x;
    width: 100%;
    height: 100%;
    margin: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @phone-sm {
      padding: 0 2.5rem;
    }
  }

  &__background-top {
    background-color: @footer-top;
  }
  
  &__background-bottom {
    background-color: @primary-text-color;
    color: @white;
    text-align: center;
    font: normal 1.1vw/1.1vw Lato;
    padding: 3vw 11vw;

    @media @tab-md {
      padding: 3vw 9vw;
      font: normal 1.5vw/1.6vw Lato;
    } 

    @media @phone-sm {
      padding: 7vw 0;
      font: normal 2.9vw/3.4vw Lato;
    }
  }

  &__top {
    height: 100%;
  }

  &__row-footer {
    padding: 4vw 0;

    @media @phone-sm {
      padding: 7vw 0;
    }
  }

  &__top-text {
    color: @white;
    text-align: center;
    font: normal 1vw/1.5vw Lato;

    @media @tab-md {
      font: normal 1.3vw/1.7vw Lato;
    }

    @media @phone-sm {
      font: normal 3vw/3.5vw Lato;
      margin-bottom: 4vw;
      width: 100%;
    }
  }

  &__text-link {
    font-size: 1vw;
    color: @secondary-color;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      color: lighten(@secondary-color, 15%);
    }

    @media @tab-md {
      font: normal 1.3vw/1.7vw Lato;
    }

    @media @phone-sm {
      font: normal 3vw/4vw Lato;
    }
  }

  &__img-pri {
    height: 5.5vw;

    @media @tab-md {
      height: 7vw;
    }

    @media @phone-sm {
      height: 15vw;
    }
  }

  &__img-anbima {
    height: 4.5vw;
    margin-top: 1.5vw;

    @media @tab-md {
      height: 6vw;
    }
    
    @media @phone-sm {
      margin-top: 3.5vw;
      height: 12vw;
    }
  }
}