.success-confirm {
  &__text {
    color: @background-top-card;
    font: normal bold 2.2vw/2.3vw Archivo;
    
    @media @tab-lg {
      font-size: 4vw;
    }
    
    @media @phone-sm {
      font-size: 4.5vw;
    }

    &--fail {
      color: red;
    }
  }

  &__body {
    height: 75%;
    
    @media @tab-lg {
      height: 100%;
    }
  }

  &__reload-button {
    margin-top: 15px;
    height: 3vw;
    width: 90%;
    color: @white;
    background-color: @text-color-disabled;
    border-color: @text-color-disabled;
    font: normal bold 1.3vw/1.5vw Archivo;

    &:hover {
      background-color: @text-color;
      border-color: @text-color;        
    }
    
    @media @phone-sm {
      height: 7vw;
      width: 38vw;
      font-size: 2.9vw;
    }
  }

  &__icon {
    margin-right: 0.5vw;
    margin-bottom: 0.3vw;
    font-size: 2.5vw;
    color: @background-top-card;

    @media @tab-lg {
      font-size: 4vw;
    }

    @media @phone-sm {
      margin-right: 1vw;
      font-size: 6.5vw;
    }

    &--fail {
      color: red;
    }
  }
}