.simulation-card {
  &__modal {
    margin-top: 3vw;
    width: @max-width-screen !important;
    padding: 0 @padding-layout-x;
    max-width:  100%;
    .ant-modal-content {
      background: @background-card-simulation;
      padding: 1.7rem 0 2.7rem 2.7rem;
      height: 37vw !important;
      box-shadow: 0px 0px 20px #00000029;
      border-radius: 35px;

      @media @desktop-xxl {
        border-radius: 2.5vw;
        padding: 1.7vw 0 2.7vw 2.7vw;
      }

      @media @tab-lg {
        min-height: 70vh;
      }

      @media @tab-md {
        padding: 1rem 0 2rem 2rem;
      }

      @media @phone-sm {
        top: 0;
        bottom: 0;
        min-height: 35rem;
        border-radius: 0;
      }
    }

    @media @desktop-xxl {
      width: @max-width-screen-xxl !important;
    }

    @media @phone-sm {
      margin-top: 0;
      padding: 0;
    }

    .ant-modal-body {
      height: 100%;

      @media @tab-lg {
        padding-left: 0;
      }
    }
  }

  &__step-section-2 {
    right: 6vw !important;
    @media @tab-lg {
      right: 7.5vw !important;
    }
    @media @phone-sm {
      right: 10.5vw !important;
    }  
  }

  &__step-section-1 {
    right: 9vw !important;
    @media @tab-lg {
      right: 12vw !important;
    }
    @media @phone-sm {
      right: 18vw !important;
    }  
  }

  &__step-section {
    height: 2.1vw;
    width: 2.1vw;
    border: 3px solid @background-card-simulation;
    border-radius: 100px;
    position: relative;
    position: absolute;
    background-color: #C1C1C1;
    top: 0;
    right: 3vw;
    transform: translateY(-50%);

    & > span {
      position: absolute;
      color: white !important;
      font-size: 1vw;
      transform: translateX(100%);

      @media @tab-lg {
        margin-top: 0.2vw;
        font-size: 1.3vw;
      }

      @media @phone-sm {
        font-size: 2.3vw;
      }
    }

    @media @tab-xl {
      border: 2px solid @background-card-simulation;
    }

    @media @tab-lg {
      height: 3vw;
      width: 3vw;
    }

    @media @phone-sm {
      height: 5vw;
      width: 5vw;
    }

    &--actived {
      height: 2.5vw;
      width: 2.5vw;
      background: @primary-color;
      transform: translate(7.5%, -60%);

      & > span {
        font-weight: bold;
        font-size: 1.2vw;
        margin-top: 0.05vw;
        transform: translate(100%);

        @media @tab-lg {
          margin-top: 0.2vw;
          font-size: 1.7vw;
        }

        @media @phone-sm {
          margin-top: 0.3vw;
          font-size: 2.8vw;
        }
      }

      @media @tab-lg {
        height: 3.5vw;
        width: 3.5vw;
      }
      
      @media @phone-sm {
        height: 6vw;
        width: 6vw;
      }
    }
  }

  &__children-body {
    height: 40vw;
    max-height: 40vw;

    @media @tab-lg {
      height: 100%;
      max-height: 100%;
    }
  }

  &__arrow-left {
    font-size: 1vw;

    @media @tab-lg {
      font-size: 2.5vw;
    }

    @media @phone-sm {
      font-size: 4vw;
    }
  }

  &__back-button {
    color: #5b6064;
    font: normal 1.3vw/1.5vw Archivo;

    @media @tab-lg {
      font: normal 2.5vw/2.7vw Archivo;
    }

    @media @phone-sm {
      font: normal 4.3vw/4.5vw Archivo;
      padding-left: 0;
    }
  }

  &__next-button {
    height: 3vw;
    width: 15vw;
    color: @white;
    font: normal bold 1.3vw/1.5vw Archivo;

    @media @tab-lg {
      height: 2rem;
      width: 10rem;
      font-size: 1rem;
    }

    @media @phone-sm {
      height: 7vw;
      width: 35vw;
      font-size: 2.9vw;
    }
  }
}
