.simulation-error {
  &__icon {
    margin-right: 0.5vw;
    margin-top: -0.2vw;
    font-size: 3vw;
    color: @warning-color;

    @media @tab-lg {
      margin-top: -0.2vw;
      font-size: 5vw;
    }

    @media @phone-sm {
      margin-top: -1vw;
      margin-right: 1vw;
      font-size: 6.5vw;
    }
  }

  &__body {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media @tab-lg {
      height: 100%;
    }
  }

  &__sub-title {
    color: @primary-text-color;
    font: normal 2vw/2.5vw Lato;
    
    @media @tab-lg {
      font: normal 4vw/4.5vw Lato;
    }

    @media @phone-sm {
      font: normal 5vw/5.5vw Lato;
    }
  }

  &__link {
    max-width: 45vw;
    text-align: center;
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;

    @media @tab-lg {
      margin-top: 5vw;
      max-width: 65vw;
    }

    @media @phone-sm {
      max-width: 80vw;
      margin-top: 8vw;
    }
  }


  &__text-explain {
    text-align: center;
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;

    @media @tab-lg {
      max-width: 90%;
      margin-top: 5vw;
    }
    
    @media @phone-sm {
      max-width: 90%;
      margin-top: 8vw;
    }
  }

  &__text {
    color: @warning-color;
    font: normal bold 2.7vw/2.8vw Archivo;

    @media @tab-lg {
      text-align: center;
      font: normal 4.5vw/4.6vw Archivo;
    }

    @media @phone-sm {
      font: normal 5.5vw/5.6vw Archivo;
    }
  }

  &__footer {
    position: absolute;
    bottom: 2vw;
    width: 90%;

    @media @phone-sm {
      padding-right: 2vw;
      width: 85vw;
      bottom: 4vw;
    }
  }
}
