.simulation-precatorio {
  &__name {
    color: @primary-text-color;
    font: normal bold 2vw/2vw Lato;
    text-align: left;

    @media @tab-xl {
      font: normal bold 2.2vw/2.5vw Lato;
    }

    @media @phone-sm {
      font: normal bold 5vw/5.2vw Lato;
    }
  }

  &__body {
    height: 55%;

    @media @tab-lg {
      height: 90%;
    }

    @media @phone-sm {
      height: 86.8%;
    }
  }

  &__body-text {
    margin-top: 0.5rem;
  }

  &__loading-icon {
    height: 75%;

    @media @tab-lg {
      height: 100%;
    }
  }

  &__body-card-background {
    transition: 150ms;
    background: #D5FF80;
    position: absolute;
    top: 0;
    border-radius: 20px 0 0 20px;
    width: 16.5vw;
    height: 6vw;

    &--disabled {
      background: #D2D2D2;
    }

    @media @phone-sm {
      height: 10vw;
      width: 19vw;
    }
  }
  
  &__sub-title {
    margin-top: 0.3vw;
    color: @primary-text-color;
    font: normal 1.4vw/1.6vw Lato;
    text-align: left;

    @media @phone-sm {
      margin-top: 1vw;
      margin-bottom: 3vw;
      font: normal bold 3.5vw/3.8vw Lato;
    }
  }

  &__overflow {
    overflow-y: auto;
    height: 100%;
    margin-top: 1vw;
    padding: 0 2vw 0 0;
  }

  &__card {
    margin-bottom: 2vw;
    border-radius: 1.5vw;
    box-shadow: 0px 0px 5px #00000029;
    cursor: pointer;

    @media @tab-md {
      margin-bottom: 4vw;
    }
  }

  &__header-card {
    transition: 150ms;
    border-radius: 1.5vw 1.5vw 0 0;
    background-color:  #006032;
    width: 100%;
    height: 5vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--disabled {
      background-color: #D2D2D2;
    }

    @media @phone-sm {
      height: 10vw;
      padding-left: 3vw;
      padding-right: 3vw;
    }
  }

  &__header-checkbox {
    color: #006032;

    @media @desktop-xxl {
      display: none;
    }
  }

  &__header-title {
    transition: 150ms;
    color: @white;
    font: normal bold 1.7vw Lato;
    & .ant-checkbox {
      top: -0.1vw;
    }

    &--disabled {
      color: @text-color-disabled;
    }

    @media @phone-sm {
      font: normal bold 3.5vw Lato;
    }
  }

  &__body-card {
    transition: 150ms;
    padding: 2vw 1.5vw 2.5vw 1.5vw;
    border-radius: 0 0 1.5vw 1.5vw;
    background: @background-card-precatorio;

    &--disabled {
      background:#E1E1E0;
    }

    @media @phone-sm {
      padding: 2vw 4vw 2.5vw 4vw;
    }
  }

  &__body-card-value {
    transition: 150ms;
    color:  #006032;
    text-align: left;
    font: normal bold 3.2vw/3.7vw Lato;
    margin-bottom: 1vw;

    &--disabled {
      color: @text-color-disabled;
    }

    @media @phone-sm {
      font: normal bold 7vw/7.5vw Lato;
    }
  }

  &__body-card-info {
    transition: 150ms;
    color:  #006032;
    font: normal 1.25vw/1.4vw Lato;

    &--disabled {
      color: @text-color-disabled;
    }

    @media @phone-sm {
      font: normal 2.5vw/2.7vw Lato;
    }
  }

  &__col-info {
    margin-right: 5vw;
  }

  &__body-card-days {
    transition: 150ms;
    text-align: right;
    height: 100%;
    margin-top: 1vw;
    margin-right: 3vw;
    font: normal bold 1.8vw/2.2vw Lato;
    color:  #006032;

    &--disabled {
      color: @text-color-disabled;
    }

    @media @phone-sm {
      margin-top: 3vw;
      font: normal bold 2.3vw/2.5vw Lato;
    }
  }

  &__body-card-days-text {
    @media @phone-sm {
      width: 18vw;
    }
  }

  &__observation {
    font: normal 1.3vw/1.5vw Lato;
    position: absolute;
    bottom: 0;
    transform: translateY(130%);
    max-width: 65%;
    text-align: center;
    left: 15%;
    right: 15%;
    color: @white;

    @media @tab-md {
      font: normal 1.7vw/1.9vw Lato;
      max-width: 100%;
      left: 10%;
      right: 10%;  
    }

    @media @tab-md {
      font: normal 2.1vw/2.1vw Lato;
      max-width: 100%;
      left: 7%;
      right: 7%;  
    }    
  }

  &__footer {
    position: absolute;
    bottom: 2vw;
    width: 90%;

    @media @phone-sm {
      padding-right: 2vw;
      width: 85vw;
      bottom: 4vw;
    }
  }
}
