.about-section {
  background-image: url('../../../assets/background-about-blue.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 48.3vw;
  height: 100%;

  &__layout {
    max-width: calc(@max-width-screen);
    padding: 5vw @padding-layout-x;
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @tab-md {
      padding: 5vw @padding-layout-x-mobile;
    }
  }

  &__row {
    margin-top: 10vw;
    @media @phone-sm {
      margin-top: 0;
      align-items: center;
    }
  }

  &__image {
    width: 32vw;

    @media @tab-xl {
      width: 35vw;
    }
  }

  &__about-title {
    max-width: 25vw;
    color: @white;
    margin-bottom: 2vw;
    margin-top: 1vw;
    font: normal 1.5vw/2vw Archivo;    

    @media @tab-lg {
      max-width: 35vw;
      font: normal 2vw/2.5vw Archivo;    
    }

    @media @phone-sm {
      max-width: 37vw;
      margin-top: 15vw;
      margin-bottom: 0;
      font: normal 2.5vw/3vw Archivo;    
    }

    @media @phone-xs {
      margin-top: 10vw;
      margin-bottom: 0;
    }
  }

  &__title {
    color: @white;
    margin-bottom: 2vw;
    font: normal 1.5vw/2vw Archivo;    
    flex-direction: column;
    margin: auto;
    margin-right: 0;
    align-items: flex-end;

    @media @tab-lg {
      font: normal 2vw/2.5vw Archivo;    
    }

    @media @phone-sm {
      font: normal 2.5vw/3vw Archivo;    
    }
  }
}