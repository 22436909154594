/* border */

.b-0 {
  border: none;
}

.b-1 {
  // border: 1px solid @border-color;
}

/* margin */

.m-0 {
  margin: 0px;
}

.m-1 {
  margin: 5px;
}

.m-1em {
  margin: 1em;
}

.m-2 {
  margin: 10px;
}

.m-3 {
  margin: 15px;
}

.m-4 {
  margin: 20px;
}

.m-5 {
  margin: 25px;
}

.m-6 {
  margin: 30px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mt-6 {
  margin-top: 30px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.mb-6 {
  margin-bottom: 30px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.ml-6 {
  margin-left: 30px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.mr-6 {
  margin-right: 30px;
}

.mx-0 {
  margin: 0 0px;
}

.mx-1 {
  margin: 0 5px;
}

.mx-2 {
  margin: 0 10px;
}

.mx-3 {
  margin: 0 15px;
}

.mx-4 {
  margin: 0 20px;
}

.mx-5 {
  margin: 0 25px;
}

.mx-6 {
  margin: 0 30px;
}

.my-0 {
  margin: 0px 0;
}

.my-1 {
  margin: 5px 0;
}

.my-2 {
  margin: 10px 0;
}

.my-3 {
  margin: 15px 0;
}

.my-4 {
  margin: 20px 0;
}

.my-5 {
  margin: 25px 0;
}

.my-6 {
  margin: 30px 0;
}

.m-auto {
  margin: auto;
}

@media screen and (max-width: 768px) {

  .m-md-0 {
    margin: 0px;
  }

  .m-md-1 {
    margin: 5px;
  }

  .m-md-2 {
    margin: 10px;
  }

  .m-md-3 {
    margin: 15px;
  }

  .m-md-4 {
    margin: 20px;
  }

  .m-md-5 {
    margin: 25px;
  }

  .m-md-6 {
    margin: 30px;
  }

  .mt-md-0 {
    margin-top: 0px;
  }

  .mt-md-1 {
    margin-top: 5px;
  }

  .mt-md-2 {
    margin-top: 10px;
  }

  .mt-md-3 {
    margin-top: 15px;
  }

  .mt-md-4 {
    margin-top: 20px;
  }

  .mt-md-5 {
    margin-top: 25px;
  }

  .mt-md-6 {
    margin-top: 30px;
  }

  .mb-md-0 {
    margin-bottom: 0px;
  }

  .mb-md-1 {
    margin-bottom: 5px;
  }

  .mb-md-2 {
    margin-bottom: 10px;
  }

  .mb-md-3 {
    margin-bottom: 15px;
  }

  .mb-md-4 {
    margin-bottom: 20px;
  }

  .mb-md-5 {
    margin-bottom: 25px;
  }

  .mb-md-6 {
    margin-bottom: 30px;
  }

  .ml-md-0 {
    margin-left: 0px;
  }

  .ml-md-1 {
    margin-left: 5px;
  }

  .ml-md-2 {
    margin-left: 10px;
  }

  .ml-md-3 {
    margin-left: 15px;
  }

  .ml-md-4 {
    margin-left: 20px;
  }

  .ml-md-5 {
    margin-left: 25px;
  }

  .ml-md-6 {
    margin-left: 30px;
  }

  .mr-md-0 {
    margin-right: 0px;
  }

  .mr-md-1 {
    margin-right: 5px;
  }

  .mr-md-2 {
    margin-right: 10px;
  }

  .mr-md-3 {
    margin-right: 15px;
  }

  .mr-md-4 {
    margin-right: 20px;
  }

  .mr-md-5 {
    margin-right: 25px;
  }

  .mr-md-6 {
    margin-right: 30px;
  }

  .mx-md-0 {
    margin: 0 0px;
  }

  .mx-md-1 {
    margin: 0 5px;
  }

  .mx-md-2 {
    margin: 0 10px;
  }

  .mx-md-3 {
    margin: 0 15px;
  }

  .mx-md-4 {
    margin: 0 20px;
  }

  .mx-md-5 {
    margin: 0 25px;
  }

  .mx-md-6 {
    margin: 0 30px;
  }

  .my-md-0 {
    margin: 0px 0;
  }

  .my-md-1 {
    margin: 5px 0;
  }

  .my-md-2 {
    margin: 10px 0;
  }

  .my-md-3 {
    margin: 15px 0;
  }

  .my-md-4 {
    margin: 20px 0;
  }

  .my-md-5 {
    margin: 25px 0;
  }

  .my-md-6 {
    margin: 30px 0;
  }
}

/* padding */

.p-0 {
  padding: 0px!important;
}

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 15px;
}

.p-4 {
  padding: 20px;
}

.p-5 {
  padding: 25px;
}

.p-6 {
  padding: 30px;
}

.pt-0 {
  padding-top: 0px!important;
}

.pt-1 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.pt-5 {
  padding-top: 25px;
}

.pt-6 {
  padding-top: 30px;
}

.pb-0 {
  padding-bottom: 0px!important;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-5 {
  padding-bottom: 25px;
}

.pb-6 {
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pl-5 {
  padding-left: 25px;
}

.pl-6 {
  padding-left: 30px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.pr-5 {
  padding-right: 25px;
}

.pr-6 {
  padding-right: 30px;
}

.px-0 {
  padding: 0 0px;
}

.px-1 {
  padding: 0 5px;
}

.px-2 {
  padding: 0 10px;
}

.px-3 {
  padding: 0 15px;
}

.px-4 {
  padding: 0 20px;
}

.px-5 {
  padding: 0 25px;
}

.px-6 {
  padding: 0 30px;
}

.py-0 {
  padding: 0px 0;
}

.py-1 {
  padding: 5px 0;
}

.py-2 {
  padding: 10px 0;
}

.py-3 {
  padding: 15px 0;
}

.py-4 {
  padding: 20px 0;
}

.py-5 {
  padding: 25px 0;
}

.py-6 {
  padding: 30px 0;
}

@media screen and (max-width: 768px) {
  .p-md-0 {
    padding: 0px;
  }

  .p-md-1 {
    padding: 5px;
  }

  .p-md-2 {
    padding: 10px;
  }

  .p-md-3 {
    padding: 15px;
  }

  .p-md-4 {
    padding: 20px;
  }

  .p-md-5 {
    padding: 25px;
  }

  .p-md-6 {
    padding: 30px;
  }

  .pt-md-0 {
    padding-top: 0px;
  }

  .pt-md-1 {
    padding-top: 5px;
  }

  .pt-md-2 {
    padding-top: 10px;
  }

  .pt-md-3 {
    padding-top: 15px;
  }

  .pt-md-4 {
    padding-top: 20px;
  }

  .pt-md-5 {
    padding-top: 25px;
  }

  .pt-md-6 {
    padding-top: 30px;
  }

  .pb-md-0 {
    padding-bottom: 0px;
  }

  .pb-md-1 {
    padding-bottom: 5px;
  }

  .pb-md-2 {
    padding-bottom: 10px;
  }

  .pb-md-3 {
    padding-bottom: 15px;
  }

  .pb-md-4 {
    padding-bottom: 20px;
  }

  .pb-md-5 {
    padding-bottom: 25px;
  }

  .pb-md-6 {
    padding-bottom: 30px;
  }

  .pl-md-0 {
    padding-left: 0px;
  }

  .pl-md-1 {
    padding-left: 5px;
  }

  .pl-md-2 {
    padding-left: 10px;
  }

  .pl-md-3 {
    padding-left: 15px;
  }

  .pl-md-4 {
    padding-left: 20px;
  }

  .pl-md-5 {
    padding-left: 25px;
  }

  .pl-md-6 {
    padding-left: 30px;
  }

  .pr-md-0 {
    padding-right: 0px;
  }

  .pr-md-1 {
    padding-right: 5px;
  }

  .pr-md-2 {
    padding-right: 10px;
  }

  .pr-md-3 {
    padding-right: 15px;
  }

  .pr-md-4 {
    padding-right: 20px;
  }

  .pr-md-5 {
    padding-right: 25px;
  }

  .pr-md-6 {
    padding-right: 30px;
  }

  .px-md-0 {
    padding: 0 0px;
  }

  .px-md-1 {
    padding: 0 5px;
  }

  .px-md-2 {
    padding: 0 10px;
  }

  .px-md-3 {
    padding: 0 15px;
  }

  .px-md-4 {
    padding: 0 20px;
  }

  .px-md-5 {
    padding: 0 25px;
  }

  .px-md-6 {
    padding: 0 30px;
  }

  .py-md-0 {
    padding: 0px 0;
  }

  .py-md-1 {
    padding: 5px 0;
  }

  .py-md-2 {
    padding: 10px 0;
  }

  .py-md-3 {
    padding: 15px 0;
  }

  .py-md-4 {
    padding: 20px 0;
  }

  .py-md-5 {
    padding: 25px 0;
  }

  .py-md-6 {
    padding: 30px 0;
  }
}

.m-3-children {
  div {
    margin-bottom: 4px;
  }
}

.center-text {
  text-align: center;
}

.text-end {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.w-auto {
  width: auto;
}

.m-w-100 {
  max-width: 100%;
}

.w-100 {
  width: 100%!important;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100% !important;
}

@media screen and (max-width: 768px) {
  .w-md-100 {
    width: 100%;
  }

  .w-md-75 {
    width: 75%;
  }

  .w-md-50 {
    width: 50%;
  }
}

// flex

.flex {
  display: flex!important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column!important;
}

.flex-row {
  flex-direction: row;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center!important;
}

.flex-justify-start {
  justify-content: flex-start!important;
}

.flex-justify-end {
  justify-content: flex-end;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.flex-align-center {
  align-items: center;
}

.flex-align-baseline {
  align-items: baseline;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.align-self-stretch {
  align-self: stretch;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.gap-1 {
  gap: 1em;
}

.grow-1 {
  flex-grow: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: row;
}

.form-step.hidden {
  display: none;
}
