#chat {
  display: none;
  @media @phone-sm {
    display: block;
    background-color: @secondary-color;
    padding: 10vw 5vw;
  }
}

.chat {
  position: fixed;
  bottom: 4rem;
  right: 10rem;
  z-index: 13;
  height: 30rem;
  background-color: @white;
  border-radius: 20px;
  max-width: 25rem;
  box-shadow: 0px 3px 15px #0000004A;

  @media @tab-xl {
    height: 27rem;
  }

  @media @tab-lg {
    right: 5rem;
    bottom: 2rem;
    height: 27.8rem;
  }

  @media @tab-md {
    max-width: 25rem;
    height: 28.5rem;
  }

  @media @phone-sm {
    background-color: @white;
    position: static;
    height: 30rem;
    margin: auto;
    border-radius: 20px;
  }

  @media @phone-xs {
    height: 25rem;
  }

  .ant-form-item-label {
    @media @tab-lg {
      margin-bottom: 0.5vw;
    }
    @media @tab-md {
      margin-bottom: 0;
    }
    @media @phone-sm {
      margin-bottom: 1vw;
    }

    & label {
      color: @text-color-disabled;

      font-size: 1rem;
      @media @tab-xl {
        font-size: 1.3vw;
      }
      @media @tab-md {
        font-size: 1.7vw;
      }
      @media @phone-sm  {
        font-size: 2.5vw;
      }
      @media @phone-xs  {
        font-size: 3vw;
      }
    }
  }

  .ant-input {
    background-color: @background-form;
    height: 3rem;
    border-radius: 0.8vw;

    @media @tab-lg {
      height: 5vw;
      margin-bottom: 1.3vw;
    }

    @media @tab-md {
      margin-bottom: 1vw;
      height: 7vw;
    }

    @media @phone-sm {
      margin-bottom: 2vw;
      border-radius: 1.2vw;
      height: 9vw;
    }
  }

  &__title {
    color: @text-color-disabled;
    font-size: 1vw;
    line-height: 3vw;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: -1vw;

    @media @tab-md {
      font-size: 2.5vw;
      line-height: 2.5vw;
    }

    @media @phone-sm {
      font-size: 3vw;
      line-height: 3vw;
    }
  }

  &__close-icon {
    font-size: 1vw;
    @media @phone-sm {
      display: none;
    }
  }

  &__sub-title {
    color: @text-color-disabled;
    font-weight: 400;
    line-height: 1;
    font-size: 1rem;
    margin-bottom: 0.3vw;
    max-width: 80%;

    @media @tab-xl {
      font-size: 1.2vw;
    }

    @media @tab-lg {
      margin-bottom: 1.3vw;
    }
    
    @media @tab-md {
      font-size: 2vw;
    }

    @media @phone-sm {
      font-size: 2.8vw;
      margin-top: 1vw;
    }
  }

  &__button {
    margin-left: auto;
    display: block;
    margin-top: 1vw;
    width: 7.5rem;
    height: 2.5rem;
    font-size: 1vw;
    
    @media @tab-xl {
      font-size: 1.3vw;
      width: 10vw;
    }
    
    @media @tab-lg {
      height: 3.5vw;
      font-size: 1.7vw;
      width: 12vw;
    }

    @media @tab-md {
      font-size: 2vw;
      width: 16vw;
      height: 4vw;
    }
    
    @media @phone-sm {
      margin-top: 3vw;
      font-size: 3.5vw;
      width: 30vw;
      height: 6vw;
    }
  }

  &__body-chat-success {
    text-align: center;
    margin-top: 3rem;
  }

  &__message-success {
    font: normal bold 1.7rem/1.8rem Lato;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vw;
    color: @background-top-card;

    @media @phone-xs {
      margin-top: 4vw;
      font: normal bold 1rem/1.2rem Lato;
    }
  }

  &__text-success {
    color: #006032;
    margin-left: auto;
    font: normal 1.5rem/1.7rem Lato;
    margin-right: auto;
    max-width: 70%;
    margin-top: 1vw;

    @media @phone-xs {
      margin-top: 4vw;
      font: normal 1rem/1.2rem Lato;
    }
  }
}