.intro-about {
  position: relative;
  background: transparent linear-gradient(90deg, #002444 30%, #003564 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 1;
  min-height: 25vw;

  @media @tab-md {
    min-height: 45vw;
  }

  @media @phone-sm {
    min-height: 75vw;
  }

  &__layout {
    max-width: calc(@max-width-screen);
    padding: 0 @padding-layout-x;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @phone-sm {
      padding: 0 @padding-layout-x-mobile;
    }
  }

  &__image {
    margin-top: 4vw;
    width: 25vw;

    @media @phone-sm {
      margin-top: 1vw;
      width: 35vw;
      display: block;
      margin-left: auto;
    }
  }

  &__logo {
    @media @phone-sm {
      width: 100%;
    }
  }

  &__title {
    margin-top: 3vw;
    font: normal 400 3.5vw/3.8vw Archivo;    
    & > span {
      font: normal bold 3.5vw/3.8vw Archivo;    
      color: @white;
    }

    @media @tab-md {
      font: normal 400 5.2vw/5.5vw Archivo;    
      & > span {
        font: normal bold 5.2vw/5.5vw Archivo;    
        color: @white;
      }
    }

    @media @phone-sm {
      flex: 0 0 100vw !important;
      text-align: center;
    }
  }

  &__text-title {
    margin-top: 4vw;
    height: 50px;
    text-align: left;
    letter-spacing: -0.8px;
    text-transform: uppercase;
    z-index: 1;
    color: @secondary-color;
  }

  &__divider {
    max-width: 33rem;
    margin: 5vw 0 2.8vw 0;
    width: 100%;
    height: 0.25vw;
    background: #5CC0F6;
    z-index: 1;

    @media @tab-xl {
      max-width: 35vw;
      height: 2px;
    }

    @media @tab-md {
      width: 100%;
      height: 2px;
    }

    @media @phone-sm {
      max-width: 90vw; 
    }
  }

  &__sub-title {
    max-width: 26vw;
    width: calc(100% - 11.5vw);
    color: @white;
    text-transform: initial;
    text-align: left;
    font: normal 400 1.5vw/1.7vw Archivo;
    letter-spacing: 0px;
    margin-bottom: 3vw;

    @media @tab-xl {
      max-width: 35vw;
      font: normal 400 2vw/2.2vw Archivo;     
    }

    @media @tab-md {
      width: calc(100% - 5vw);
      font: normal 400 3.5vw/4vw Archivo;
    }

    @media @phone-sm {
      width: calc(100%);
      font: normal 400 3.5vw/3.5vw Archivo;
    }

    @media @phone-xs {
      margin-top: 5vw;
    }
  }

  &__bottom-text {
    text-transform: initial;
    max-width: 25vw;
    font: normal 400 2vw/2.2vw Archivo;
    color: @white;
    & > span {
      font: normal bold 2vw/2.2vw Archivo;
      color: @secondary-color;
    }

    @media @tab-md {
      margin-top: 4vw;
      max-width: 40vw;
      font: normal 400 3.5vw/4vw Archivo;
      & > span {
        font: normal 400 3.5vw/4vw Archivo;
      }
    }

    @media @phone-sm {
      margin-top: 6vw;
      font: normal 400 3.5vw/3.5vw Archivo;
      & > span {
        font: normal 400 3.5vw/3.5vw Archivo;
      }
    }

    @media @phone-xs {
      margin-top: 6vw;
      max-width: 100vw;
    }
  }

  &__video {
    @media (min-width: 1661px) {
      margin-right: max(6.2vw);
    }

    padding-right: @padding-layout-x;
    position: absolute;
    right: 0;
    margin-top: 1rem;
    top: 22vw;
    border: none;
    width: 50vw;
    height: 23vw;
    opacity: 1;
    
    @media @tab-xl {
      top: 25vw;
    }
   
    @media @tab-lg {
      top: 27vw;
    }

    @media @tab-md {
      top: 30vw;
      width: 55vw;
      height: 30vw;
      padding-right: @padding-layout-x-mobile;
    }

    @media @phone-sm {
      top: 50vw;
      width: 94vw;
      height: 49.5vw;
    }
  }
}