.terms-modal {
  .ant-modal {
    width: 80vw !important;
    max-width: 1280px;
  }
  
  .ant-modal-title {
    color: @primary-color;
    font-size: 24px;
    text-transform: uppercase;
  }
  
  .ant-modal-body {
    height: 70vh !important;
    overflow-y: auto;
    padding: 1vw;

    .ant-card-bordered {
      border: none;
    }

    @media @tab-md {
      max-height: 60vh;
    }
  }

  .ant-modal-footer,
  .ant-modal-header {
    padding: 1.25em;
  }
  
  .captcha {
    .ant-modal {
      width: 80vw !important;
      max-width: 950px;
    }
  }
}