.simulation-redirect {
  &__text {
    margin-top: 2vw;
    color: @background-top-card;
    font: normal bold 2.2vw/2.3vw Archivo;

    @media @tab-lg {
      text-align: center;
      font: normal bold 3.7vw/3.8vw Lato;
    }

    @media @phone-sm {
    font: normal bold 5vw/5.3vw Archivo;
    }

    &--fail {
      color: red;
    }
  }

  &__item {
    @media @tab-lg {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__text-body-limit {
    max-width: 60vw;
    margin: auto;
    padding-top: 2vw;
    padding-bottom: 2vw;

    @media @tab-lg {
      font: normal bold 3.5vw/3.5vw Lato;
    }

    @media @phone-sm {
      padding-top: 6vw;
      padding-bottom: 6vw;
      max-width: initial;
    }
  }

  &__icon {
    margin-top: 2vw;
    margin-right: 0.5vw;
    margin-bottom: 0.3vw;
    font-size: 2.5vw;
    color: @background-top-card;

    @media @tab-lg {
      font-size: 6vw;
    }

    @media @phone-sm {
      font-size: 12vw;
    }

    &--fail {
      color: red;
    }
  }

  &__link {
    font-weight: bold;
    color: @primary-text-color;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text-body {
    margin-top: 3.5vw;
    margin-bottom: 1vw;
    color: @primary-text-color;
    font: normal 1.5vw/2vw Lato;
    text-align: center;

    @media @tab-lg {
      font: normal bold 2.5vw/3vw Lato;
    }

    @media @phone-sm {
      font: normal 5vw/5.5vw Lato;
    }
  }
}