#root .faq {  
  &__layout {
    max-width: calc(@max-width-screen);
    padding: 2vw @padding-layout-x;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 5vw;

    @media @desktop-xxl {
      max-width: @max-width-screen-xxl;
    }

    @media @tab-lg {
      padding: 0 @padding-layout-x;
    }

    @media @phone-sm {
      padding: 3vw @padding-layout-x-mobile;
    }
  }

  &__title {
    text-align: left;
    font: normal 600 3vw/3vw Archivo;
    letter-spacing: 0px;
    color: @primary-text-color;
    text-transform: uppercase;
    margin-bottom: 0.3rem;

    @media @tab-lg {
      font: normal 600 4vw/4.5vw Archivo;
    }

    @media @phone-sm {
      font: normal 600 7vw/7.5vw Archivo;
    }
  }

  &__sub-title {
    font: normal 1.5vw/1.7vw Lato;
    letter-spacing: 0px;
    color: @primary-text-color;
    margin-bottom: 5vw;
    
    @media @tab-lg {
      font: normal 2vw/2.2vw Archivo;
    }

    @media @phone-sm {
      width: 80%;
      font: normal 4vw/4.2vw Archivo;
    }
  }

  &__card {
    background-color: @primary-color;
    border-radius: 100px;
    min-height: 4vw;
    margin-top: 1.3vw;
    display: flex;
    align-content: center;
    cursor: pointer;

    &--collapsed {
      transition: background-color 150ms;
      border: 2px solid @primary-color;
      background-color: @white;

      @media @phone-sm {
        border: 1px solid @primary-color;
      }
    }

    @media @tab-lg {
      height: 6vw;
    }

    @media @tab-md {
      height: 6vw;
    }

    @media @phone-sm {
      margin-top: 4vw;
      border-radius: 3vw;
      min-height: 15vw;
    }
  }
  
  &__header {
    height: 100%;
    margin: auto;
    width: calc(100% - 2.5vw);
    cursor: pointer;
    color: white;

    @media @tab-lg {
      width: calc(100% - 5vw);
    }

    @media @phone-sm {
      width: calc(100% - 10vw);
    }
  }

  &__body {
    z-index: -1;
    position: relative;
    background-color: @body-card;
    margin-top: -2.5vw;
    padding-top: 2.5vw;
    border-radius: 0 0 2vw 2vw;
  }

  &__body-text {
    text-align: left;
    padding: 1vw 1.5vw;
    font: normal 1.4vw/1.7vw Lato;
    color: @primary-text-color;

    @media @tab-lg {
      font: normal 2vw Lato;
      padding: 1.5vw 2.5vw; 
    }

    @media @phone-sm {
      font: normal 4vw Lato;
      padding: 3vw 5vw; 
    }
  }

  &__arrow-down {
    transition: 250ms;
    color: @primary-text-color;
    font-size: 2vw;
    justify-content: flex-end;

    @media @tab-lg {
      font-size: 3vw; 
    }

    @media @phone-sm {
      font-size: 7vw;
    }
  }

  &__arrow-up {
    transition: 250ms;
    color: @secondary-color;
    font-size: 2vw;
    transform: rotate(180deg);

    @media @tab-lg {
      font-size: 3vw; 
    }
    
    @media @phone-sm {
      font-size: 7vw;
    }
  }

  &__card-title {
    width: 95%;
    font: normal 1.5vw Lato;
    color: @white;

    &--collapsed {
      color: @primary-text-color;
    }

    @media @tab-lg {
      font: normal 2vw Lato; 
    }

    @media @phone-sm {
      font: normal 4vw Lato; 
    }
  }

  &__button-more {
    display: block;
    margin: 3vw auto;
    height: 3vw;
    width: 25vw;
    font-size: 1.3vw;

    @media @tab-md {
      font-size: 2vw;
      margin: 3vw auto;
      height: 4vw;  
    }

    @media @phone-sm {
      font-size: 4vw;
      margin: 5vw auto;
      height: 8vw;
      width: 45vw;
    }
  }
}