.confirm-number {
  &__body {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media @tab-lg {
      height: 100%;
    }
  }

  &__input-title {
    color: @primary-text-color;
    text-transform: uppercase;
    font: normal bold 1.2vw/1.5vw Lato;

    @media @tab-lg {
      font: normal bold 3vw/3.3vw Lato;
    }

    @media @phone-sm {
      margin-bottom: 2vw;
      font: normal bold 3.5vw/4vw Lato;
    }
  }

  &__send-code {
    margin-top: 2vw;
    color: @primary-text-color;
    font: normal 1vw/1.2vw Lato;

    @media @tab-lg {
      margin-top: 0;
      font: normal 2vw/2.2vw Lato;
    }

    @media @phone-sm {
      margin-top: 1.5vw;
      font: normal 4vw/4.2vw Lato;
    }
  }

  &__send-code-link {
    text-decoration: underline;
    font: normal 1vw/1.2vw Lato;
    color: @primary-color;
    margin-top: -0.5rem;

    & span {
      text-decoration: underline;
    }

    &:hover {
      color: lighten(@primary-color, 5%);
      text-decoration: underline;
    }

    @media @tab-lg {
      margin-top: -0.6rem;
      font: normal 2vw/2.2vw Lato;
    }

    @media @phone-sm {
      margin-top: -0.5rem;
      font: normal 4vw/4.2vw Lato;
    }
  }
  &__footer {
    position: absolute;
    bottom: 2vw;
    width: 90%;

    @media @phone-sm {
      padding-right: 2vw;
      width: 85vw;
      bottom: 4vw;
    }
  }
}

.pincode-input-container {
  .pincode-input-text {
    height: 4.5vw !important;
    width: 4vw !important;
    padding: 0 !important;
    margin: 1vw 0.3vw !important;
    text-align:center;
    font: normal 1.5vw/1.8vw Lato;
    border: 1px solid #053E2554 !important;
    background: @white !important;
    width: 50px;
    height: 50px;
    border-radius: 0.8vw;

    @media @tab-lg {
      height: 12vw !important;
      width: 10.5vw !important;
      border-radius: 1.5vw;
      font: normal 4.5vw/4.7vw Lato;
    }

    @media @phone-sm {
      height: 14vw !important;
      width: 12.5vw !important;
      border-radius: 1.5vw;
      font: normal 6vw/6.2vw Lato;
    }
  }

 .pincode-input-text:focus {
    outline:none;
    box-shadow:none;
    border-color: @primary-text-color !important;
  }
}