//ANIMATION
.floating-arrow {
  animation-name: floating-arrow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes floating-arrow {
  from {
    transform: translate(0, 0)
  }
  65% {
    transform: translate(0, 1vw)
  }
  to {
    transform: translate(0, 0)
  }
}

.floating-pin {
  animation-name: floating-pin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes floating-pin {
  from {
    transform: translate(-50%, 0)
  }
  65% {
    transform: translate(-50%, -1vw)
  }
  to {
    transform: translate(-50%, 0)
  }
}

.fade-in {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.fade-out {
  animation: fadeOutAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeOutAnimation {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
   }
}

.get-in-from-left {
  animation-name: get-in-from-left;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes get-in-from-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(-10%);
  }
  80% {
    opacity: 0.3;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes get-in-from-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(10%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.get-in-from-right {
  animation-name: get-in-from-right;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes get-in-from-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    transform: translateX(10%);
  }
  80% {
    opacity: 0.3;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

//html
html {
  scroll-behavior: smooth;
}

.simulation-layout {
  margin-top: 102px;

  @media @tab-md {
    margin-top: 70px;
  }
}

.primary-color {
  color: @primary-color;
}

.secondary-color {
  color: @secondary-color;
}

//SCROLLBAR
&::-webkit-scrollbar{
  width: 0.7rem;
  height: 0.7rem;
  @media @tab-xl {
    width: 0.5rem;
    height: 0.5rem;
  }
}
&::-webkit-scrollbar-corner {
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

&::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #9B9C9D;
  border-radius: 20px;
}

&::-webkit-scrollbar-thumb:hover {
  background-color: lighten(#9B9C9D, 5%);
  border: 3px solid lighten(#9B9C9D, 5%);
}

//Modal
.ant-modal {
  @media @phone-sm {
    margin: 0 !important;
  }
}

// Buttons
.ant-btn{
  font-weight: 400;
  border-radius: 50px;
  @media @desktop-xxl {
    border-radius: 5vw;
  }
}

.ant-btn-default {
  font-weight: 600;
  color: @primary-color;
}

.ant-btn-primary:hover{
  border-color: @primary-color;
  color: @primary-color;
}

.ant-btn:focus{
  border-color: @primary-color;
  color: @primary-color;
}

.ant-btn-primary[disabled] {
  border-color: @text-color-disabled;
  background: @text-color-disabled;  
  
  &:hover {
    border-color: @text-color;
    background: @text-color;
  }
}

.ant-btn::before {
  background: none !important;
}

.ant-btn-primary {
  color: @white !important;
  background: @primary-color;
  border-color: @primary-color;
  font-weight: bold;
  letter-spacing: 0px;
  font: normal normal bold 1.2rem/2rem Lato;
  line-height: 1;
  &:hover{
    opacity: 0.7;
    transition: 0.3s;
    background: @primary-color;
    border-color: @primary-color;
  }
  &:focus{
    background: @primary-color;
    border-color: @primary-color;
  }
}

.ant-btn-link:hover, .ant-btn-link:focus{
  border-color: transparent;
}

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
  opacity: 0.7;
  transition: 0.3s;
}

.ant-btn-icon-only{
  padding: 0;
}

//FORM
.ant-input, .ant-picker {
  border-radius: 0.7vw;
  height: 3vw;

  @media @tab-lg {
    height: 4vw;
  }

  @media @tab-md {
    height: 3vw;
  }

  @media @phone-sm {
    height: 8vw;
  }
}

.ant-input-affix-wrapper {
  font-size: 18px;
  border-radius: 12px;

  @media @tab-xl {
    font-size: 14px
  }

  @media @tab-md {
    font-size: 18px
  }

  @media @phone-sm {
    font-size: 14px;
  }
}

.ant-form-item-explain, .ant-form-item-explain-error {
  font-size: 0.9vw !important;
  min-height: 0 !important;
  text-align: center;
}

.ant-form-item-label {
  @media @tab-lg {
    line-height: 1 !important;
  }
  @media @tab-md {
    line-height: 1.8 !important;
  }
  @media @phone-sm {
    line-height: 2.4 !important;
  }
  @media @phone-xs {
    line-height: 1.8 !important;
  }
}

.ant-form-item-control {
  @media @phone-sm {
    margin-top: -5px;
    margin-bottom: -5px;
  }
}

.ant-form-item {
  margin-bottom: 0.2vw;

  @media @tab-xl {
    margin-bottom: 1px;
  }
}

.ant-input[disabled] {
  background: #DCDDDC !important;
}

::placeholder {
  @media @tab-md {
    font-size: 2.5vw;
  }

  @media @phone-sm {
    font-size: 3vw;
  }

  @media @phone-xs {
    font-size: 4vw;
  }
}

.ant-checkbox-inner {
  border-radius: 29px;
  height: 1.1rem;
  width: 1.1rem;
}

.ant-form-item-label {
  padding: 0!important;
  & label {
    color: @text-color !important;
    font-size: 1.1vw;

    @media @tab-md {
      font-size: 2.3vw;
    }

    @media @phone-xs {
      font-size: 3vw;
    }
  }
}

.ant-form-item-control-input {
  min-height: initial;
}

.ant-form-item-control-input .ant-form-item-control-input-content .ant-input-affix-wrapper input {
  padding: 0 5px;
}

.ant-input:focus, .ant-input-focused, .ant-input-number:focus, .ant-input-number-focused {
  border-color: @primary-color;
  box-shadow:initial 0 0 0 1px @primary-color;
}

.ant-input-suffix{
  color: @primary-color;
}

.ant-form-item-explain, .ant-form-item-explain-error {
  @media @tab-md {
    font-size: 2vw !important;
    min-height: fit-content;
  }

  @media @phone-xs {
    font-size: 2.5vw !important;
    min-height: fit-content;
  }
}

//NOTIFICATION
.ant-notification-notice {
  border-radius: 0.8vw;
  font: normal 1rem/1.4rem Lato;
  background-color: @background-form;

  @media @phone-xs {
    font: normal 0.9rem/1.4rem Lato;
  }
  & .ant-notification-notice-message {
    color: @primary-text-color;
    font: normal 1rem/1.4rem Lato;

    @media @phone-xs {
      font: normal 0.9rem/1.4rem Lato;
    }
  }
}
