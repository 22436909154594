.simulation-form {
  background-color: @background-form;
  border-radius: 29px;
  margin-top: -12.5vw;
  margin-left: auto;
  max-width: 30vw;
  min-width: 28rem;
  z-index: 1;

  @media @tab-xl {
    min-width: 20rem;
    max-width: 37vw;
    margin-top: -13vw;
  }
  
  @media @tab-lg {
    margin-top: -15vw;
    .ant-card-body {
      padding: 18px;
    }
  }

  @media @desktop-xxl {
    border-radius: 2vw;
    .ant-card-body {
      padding: 1.5vw;
    }
  }

  @media @tab-md {
    border-radius: 3.5vw;
    min-width: auto;
    max-width: 90vw;
    margin: auto;
    transform: translate(0, 30vw);
  }

  @media @phone-sm {
    transform: translate(0, 30vw);
  }

  @media @phone-xs {
    transform: translate(0, 40vw);
  }

  &__sindifisco-image {
    height: 3.5vw;
    margin-top: 4vw;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media @tab-md {
      height: 8vw;
      margin-top: 8vw;
    }

    @media @phone-sm {
      height: 10vw;
      margin-top: 10vw;
    }
  }

  .react-date-picker {
    height: 3.3vw;
    width: 100%;

    @media @tab-md {
      height: 7vw;
    }

    @media @phone-sm {
      height: 8vw;
    }

    @media @phone-xs {
      height: 10vw;
    }
    
    & .react-date-picker__wrapper {
      transition: 300ms;
      border-radius: 0.7vw;
      border: 1px solid #d9d9d9;
      background-color: @white;
      padding: 0 0.5vw;
      
      @media @tab-md {
        border-radius: 1.3vw;
        padding: 0 1vw;
      }
      
      &:hover, &:focus {
        border-color: @primary-color;
        cursor: pointer;
      }
    }
  }
  
  .react-date-picker__inputGroup {
    font-size: 1.2vw;

    @media @tab-md {
      font-size: 2.5vw;
    }

    @media @phone-xs {
      font-size: 3.5vw;
    }
  }

  &__clear {
    margin-right: -0.2vw;

    @media @tab-lg {
      font-size: 1vw;
      padding-bottom: 2vw;
    }
    
    @media @tab-md {
      font-size: 2vw;
    }

    @media @phone-xs {
      font-size: 3vw;
    }
    
    &:hover {
      color: @primary-color;
    }
  }

  &__calendar {
    font-size: 1.5vw;

    @media @tab-md {
      font-size: 2.5vw;
    }

    @media @phone-xs {
      font-size: 3.5vw;
    }

    &:hover {
      color: @primary-color;
    }
  }

  .react-date-picker__inputGroup__input:invalid {
    background: none;
  }

  #birthdate {
    padding-top: 1px;
  }

  .ant-input, #birthdate {
    font-size: 1.2vw;

    @media @tab-md {
      font-size: 2.5vw;
    }

    @media @phone-xs {
      font-size: 3.5vw;
    }
  }

  .ant-picker {
    font-size: 1.7vw;

    @media @tab-md {
      font-size: 3vw !important;
    }
  }

  &__SMS-text {
    white-space: nowrap;
    font-size: 0.8vw;
  }

  &__title {
    color: @primary-text-color;
    font-size: 1.5vw;
    line-height: 2vw;
    font-weight: bold;
    text-transform: uppercase;

    @media @tab-md {
      font-size: 4vw;
      line-height: 4vw;
    }

    @media @phone-xs {
      font-size: 5vw;
      line-height: 5vw;
    }
  }

  &__sub-title {
    color: @primary-text-color;
    font-weight: 400;
    line-height: 1;
    font-size: 1.2vw;
    margin-top: 2.5vw;
    margin-bottom: 1vw;
    
    @media @tab-md {
      font-size: 2.5vw;
      margin-top: 5vw;
      margin-bottom: 2vw;
    }

    @media @phone-sm {
      margin-top: 6vw;
      font-size: 3.5vw;
      margin-bottom: 3vw;
    }
  }

  &__form {
    margin-top: 0.8vw;
    text-align: center;
    padding: 0 2rem;

    @media @tab-lg {
      margin-top: 0.3vw;
    }

    @media @phone-sm {
      padding: 0;
    }

    .ant-input, .ant-picker {
      height: 2.5vw;
      width: 100%;

      @media @tab-md {
        height: 7vw;
      }

      @media @phone-sm {
        height: 8vw;
      }

      @media @phone-xs {
        height: 8vw;
      }
    }
  }

  &__input-custom {
    margin-top: 0.2rem;

    @media @phone-sm {
      margin-top: 1rem;
    }
  }

  &__primary-color {
    color: @primary-text-color;
  }

  &__checkbox {
    & .ant-checkbox + span {
      padding-right: 0.125rem;
    }

    .ant-checkbox-inner {
      height: 1.2vw;
      width: 1.2vw;
      &:after {
        left: 0;
        right: 2px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      @media @tab-xl {
        height: 1.4vw;
        width: 1.4vw;
      }

      @media @tab-lg {
        height: 1.5vw;
        width: 1.5vw;
      }

      @media @tab-md {
        height: 2.3vw;
        width: 2.3vw;

        &:after {
          right: 4px;
        }
      }

      @media @tab-md {
        height: 3vw;
        width: 3vw;

        &:after {
          right: 4px;
        }
      }
    }

    @media @tab-md {
      padding-top: 1.5vw;
      padding-bottom: 1vw;  
    }
  }

  &__terms {
    margin-top: 1.5vw;
    & a {
      font-size: 1.2vw;
      color: @primary-text-color;
      text-decoration: underline;

      &:hover {
        color: lighten(@primary-text-color, 15%);
        text-decoration: underline;
      }
    }
    & span {
      font-size: 1.2vw;
      color: @primary-text-color;

      &:hover {
        color: lighten(@primary-text-color, 15%);
      }
    }

    @media @tab-lg {
      margin-top: 0.2vw;
      & a {
        font-size: 1.2vw;
      }

      & span {
        font-size: 1.2vw;
      }
    }

    @media @tab-md {
      margin-top: 3vw;
      & a {
        font-size: 2vw;
      }

      & span {
        font-size: 2vw;
      }
    }

    @media @phone-sm {
      margin-top: 6vw;
      & a {
        font-size: 3.5vw;
      }

      & span {
        font-size: 3.5vw;
      }
    }
  }

  ::placeholder {
    @media @tab-md {
      font-size: 2.5vw;
    }

    @media @phone-sm {
      font-size: 3vw;
    }

    @media @phone-xs {
      font-size: 4vw;
    }
  }

  &__button {
    margin-top: 3vw;
    width: 40%;
    height: 3.2vw;
    font-size: 1.35vw;
    margin-left: auto;
    margin-right: auto;
    display: block;

    @media @tab-md {
      margin-top: 6vw;
      height: 6vw;
      font-size: 2.6vw;
    }

    @media @phone-sm {
      margin-top: 8vw;
    }

    @media @phone-xs {
      height: 8vw;
      font-size: 3.1vw;
    }
  }
}